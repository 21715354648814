import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customSizeFormValidationSchema } from "../../validations/validationScheme";
import { plainGetAPI } from "../../utils/apiService";
import {
  saveCustomWindowSizeFormData,
  setFormStep,
  updateRecord,
} from "../../redux/windowFormSlice";

const CustomWindowSize = () => {
  const dispatch = useDispatch();
  const { customerId, selectedProductType } = useSelector((store) => store.topForm);
  const { formStep, customWindowSizeFormData, isLoading } = useSelector(
    (store) => store.windowForm
  );
  const [standardSizes, setStandardSizes] = useState([]);
  const formik = useFormik({
    initialValues: {
      id: customerId,
      custom_width: customWindowSizeFormData?.custom_width || "",
      custom_height: customWindowSizeFormData?.custom_height || "",
      form_step: formStep,
      product_type: selectedProductType
    },
    validationSchema: customSizeFormValidationSchema,
    onSubmit: (values) => {
      dispatch(saveCustomWindowSizeFormData(values));
      dispatch(updateRecord(values));
    },
  });

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    formik;

  useEffect(() => {
    getStandardSizes();
  }, []);

  const getStandardSizes = async () => {
    const url = `/api/getstandardsizes`;
    const list = await plainGetAPI(url);
    const { code, message, data } = list;
    if (code !== 200) {
      console.log(message);
    }

    setStandardSizes(data);
  };

  const nextStep = () => {
    handleSubmit();
  };

  const handlePrevious = () => {
    dispatch(setFormStep(formStep));
  };

  return (
    <div className="main_wrap">
      <form className="main_form_wrap" onSubmit={handleSubmit}>
        <div className="form_step_wrap">
          {/* Step 4 */}
          <div className="form_step_item">
            <div className="form_head_cont">
              <h1 className="form_head">Custom Size</h1>
              <div className="form_note">
                <p>
                  For custom windows <u>minimum</u> width is 36" &amp;{" "}
                  <u>maximum</u> width is 96". <u>Minimum</u> height is 36"
                  &amp; <u>maximum</u> is 60".
                </p>
              </div>
            </div>
            <div className="form_wrap">
              <div className="form_row">
                <div className="form-group width_half">
                  <label className="form-label">Width*</label>
                  <input
                    type="text"
                    className="form-control"
                    id="customWidth"
                    name="custom_width"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.custom_width}
                  />
                  {touched.custom_width && errors.custom_width ? (
                    <div className="custom-invalid-feedback">
                      {errors.custom_width}
                    </div>
                  ) : null}
                </div>
                <div className="form-group width_half">
                  <label className="form-label">Height*</label>
                  <input
                    type="text"
                    className="form-control"
                    id="customHeight"
                    name="custom_height"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.custom_height}
                  />
                  {touched.custom_height && errors.custom_height ? (
                    <div className="custom-invalid-feedback">
                      {errors.custom_height}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_wrap btn_two_wrap d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-primary btn_sec w-auto"
            onClick={handlePrevious}
            disabled={isLoading}
          >
            <i className="bi bi-chevron-left"></i> Back
          </button>
          <button
            type="button"
            onClick={nextStep}
            className="btn btn-primary btn_main w-auto"
            disabled={isLoading}
          >
            Next <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default CustomWindowSize;
