import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { doorSwingFormValidationSchema } from "../../validations/validationScheme";
import {
  saveDoorSwingFormData,
  setDoorFormStep,
  updateRecord,
} from "../../redux/doorFormSlice";
import { useEffect, useState } from "react";
import { plainGetAPI } from "../../utils/apiService";

const DoorSwing = () => {
  const dispatch = useDispatch();
  const { customerId, selectedProductType } = useSelector(
    (store) => store.topForm
  );
  const { doorFormStep, doorSizeFormData, doorSwingFormData, doorIsLoading } = useSelector(
    (store) => store.doorForm
  );

  const [doorSwingOptions, setDoorSwingOptions] = useState([]);
  const [foldDirectionOptions, setFoldDirectionOptions] = useState([]);
  

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: customerId,
      swing_door_position: doorSwingFormData?.swing_door_position || "",
      choose_fold_direction: doorSwingFormData?.choose_fold_direction || "",
      form_step: doorFormStep,
      product_type: selectedProductType,
    },
    validationSchema: doorSwingFormValidationSchema,
    onSubmit: (values) => {
      dispatch(saveDoorSwingFormData(values));
      dispatch(updateRecord(values));
    },
  });

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    formik;

  useEffect(() => {
    getDoorSwingOptions();
  }, []);

  useEffect(() => {
    setFoldDirectionOptions([]);
    if(values.swing_door_position === "") {
        return;
    }
    values.choose_fold_direction = "";
    // dispatch(saveDoorSwingFormData(values));
    getFoldDirectionOptions();
  }, [values.swing_door_position]);

  

  const getDoorSwingOptions = async () => {
    const { width, height, number_of_panels } = doorSizeFormData;
    const url = `/api/getdoorswingoptions?id=${customerId}&width=${width}&height=${height}&number_of_panels=${number_of_panels}`;
    const list = await plainGetAPI(url);
    const { code, message, data } = list;
    if (code !== 200) {
      console.log(message);
    }

    setDoorSwingOptions(data);
  };

  const getFoldDirectionOptions = async () => {
    const selectedSwingDoorPosition = values.swing_door_position; 
    const url = `/api/getfolddirectionoptions?id=${customerId}&swing_door_position=${selectedSwingDoorPosition}`;
    const list = await plainGetAPI(url);
    const { code, message, data } = list;
    if (code !== 200) {
      console.log(message);
    }

    setFoldDirectionOptions(data);
  }


  const nextStep = () => {
    handleSubmit();
  };

  const handlePrevious = () => {
    dispatch(setDoorFormStep(doorFormStep));
  };

  return (
    <div className="main_wrap">
      <form className="main_form_wrap" onSubmit={handleSubmit}>
        <div className="form_step_wrap">
          <div className="form_step_item">
            <div className="form_head_cont">
              <h1 className="form_head">Pedestrian Door Placement</h1>
              <div className="form_note">First choose which side you want a pedestrian door (aka swing door or walk-through door). Remember this is AS VIEWED FROM THE INSIDE.</div>
            </div>
            <div className="form_wrap">
              <div className="form_row">
                <div className="form-group width_full">
                  <label className="form-label">Choose Pedestrian Door Location. <b><u>AS VIEWED FROM THE INSIDE</u></b>*</label>
                  <div className="form-check-img-wrap form-check-img-wrap-new">
                  {doorSwingOptions.length > 0 &&
                    doorSwingOptions.map((item, index) => {
                      return (
                        <div className="form-check" key={`door_swing_${index}`}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="swing_door_position"
                            id={`swing_door_position${index}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            key={"input-" + item + index}
                            value={item.option_label}
                            defaultChecked={
                              String(values.swing_door_position).toLowerCase() ===
                              String(item.option_label).toLowerCase()
                            }
                          />
                          <label
                            className="form-check-label form-check-img-item"
                            htmlFor={`swing_door_position${index}`}
                          >
                            {item.option_label}
                            <img
                              src={item.option_img}
                              alt={item.option_label}
                            />
                          </label>
                        </div>
                      );
                    })}
                  {touched.swing_door_position && errors.swing_door_position ? (
                    <div className="custom-invalid-feedback">
                      {errors.swing_door_position}
                    </div>
                  ) : null}
                </div>
                </div>
              </div>
              <div className="form_row">
                <div className="form-group width_full">
                  <label className="form-label">Choose the fold direction*</label>
                  <div className="form-check-img-wrap form-check-img-wrap-new">
                  {foldDirectionOptions.length > 0 &&
                    foldDirectionOptions.map((item, index) => {
                      return (
                        <div className="form-check" key={`fold_direction_${index}`}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="choose_fold_direction"
                            id={`choose_fold_direction${index}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            key={"input-" + item.option_label + index}
                            value={item.option_label}
                            defaultChecked={
                              (String(values.choose_fold_direction).toLowerCase() ===
                              String(item.option_label).toLowerCase())
                            }
                          />
                          <label
                            className="form-check-label form-check-img-item"
                            htmlFor={`choose_fold_direction${index}`}
                          >
                            {item.option_label}
                            <img
                              src={item.option_img}
                              alt={item.option_label}
                            />
                          </label>
                        </div>
                      );
                    })}
                  {touched.choose_fold_direction && errors.choose_fold_direction ? (
                    <div className="custom-invalid-feedback">
                      {errors.choose_fold_direction}
                    </div>
                  ) : null}
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_wrap btn_two_wrap d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-primary btn_sec w-auto"
            onClick={handlePrevious}
            disabled={doorIsLoading}
          >
            <i className="bi bi-chevron-left"></i> Back
          </button>
          <button
            type="button"
            onClick={nextStep}
            className="btn btn-primary btn_main w-auto"
            disabled={doorIsLoading}
          >
            Next <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default DoorSwing;
