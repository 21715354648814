import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { quantityFormValidationSchema } from "../../validations/validationScheme";
import {
  saveQuantityFormData,
  setFormStep,
  updateRecord,
} from "../../redux/windowFormSlice";
import { useEffect, useState } from "react";
import { plainGetAPI } from "../../utils/apiService";

const Quantity = () => {
  const dispatch = useDispatch();
  const { customerId, selectedProductType } = useSelector((store) => store.topForm);
  const { formId, formStep, quantityFormData, isLoading } = useSelector(
    (store) => store.windowForm
  );

  const formik = useFormik({
    initialValues: {
      id: customerId,
      quantity: quantityFormData?.quantity || 1,
      form_step: formStep,
      product_type: selectedProductType
    },
    validationSchema: quantityFormValidationSchema,
    onSubmit: (values) => {
      dispatch(saveQuantityFormData(values));
      dispatch(updateRecord(values));
    },
  });

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
  } = formik;

  const nextStep = () => {
    handleSubmit();
  };

  const increaseQty = () => {
    if (values.quantity === 10) {
      return;
    }
    values.quantity = setFieldValue("quantity", parseInt(values.quantity) + 1);
  };

  const decreaseQty = () => {
    if (values.quantity === 1) {
      return;
    }
    values.quantity = setFieldValue("quantity", parseInt(values.quantity) - 1);
  };

  const handlePrevious = () => {
    dispatch(setFormStep(formStep));
  };

  return (
    <div className="main_wrap">
      <form className="main_form_wrap" onSubmit={handleSubmit}>
        <div className="form_step_wrap">
          <div className="form_step_item">
            <div className="form_head_cont">
              <h1 className="form_head">Quantity</h1>
              <h4 className="form_subhead">
                How many windows of the <u>same size?</u>
              </h4>
            </div>
            <div className="form_wrap">
              <div className="form_row justify-content-center">
                <div className="form-group width_half">
                  <label className="lbl_main">Quantity</label>
                  <div className="input-group align-items-center justify-content-space-between">
                    <input
                      type="button"
                      value="-"
                      className="button-minus icon-shape icon-sm"
                      data-field="quantity"
                      onChange={decreaseQty}
                      onClick={decreaseQty}
                    />
                    <input
                      type="number"
                      step="1"
                      max="10"
                      name="quantity"
                      className="quantity-field text-center"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.quantity}
                    />
                    <input
                      type="button"
                      value="+"
                      className="button-plus icon-shape icon-sm"
                      data-field="quantity"
                      onChange={increaseQty}
                      onClick={increaseQty}
                    />
                  </div>
                  {touched.quantity && errors.quantity ? (
                    <div className="custom-invalid-feedback">
                      {errors.quantity}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_wrap btn_two_wrap d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-primary btn_sec w-auto"
            onClick={handlePrevious}
            disabled={isLoading}
          >
            <i className="bi bi-chevron-left"></i> Back
          </button>
          <button
            type="button"
            onClick={nextStep}
            className="btn btn-primary btn_main w-auto"
            disabled={isLoading}
          >
            Next <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Quantity;
