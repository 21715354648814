import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { doorTypeFormValiationSchema } from "../../validations/validationScheme";
import { saveDoorTypeFormData, setDoorFormStep, updateRecord, resetAndRestartDoorForm } from "../../redux/doorFormSlice";
import { setTopFormStep } from '../../redux/topFormSlice';
import { useEffect, useState } from "react";
import { plainGetAPI } from "../../utils/apiService";

const DoorType = () => {
  const dispatch = useDispatch();
  const { customerId, selectedProductType } = useSelector((store) => store.topForm);
  const { doorFormStep, doorTypeFormData, doorIsLoading } = useSelector((store) => store.doorForm);
 
  const [doorTypeOptions, setDoorTypeOptions] = useState([]);
  const formik = useFormik({
    initialValues: {
      id: customerId,
      door_type: doorTypeFormData?.door_type || "",
      form_step: doorFormStep,
      product_type: selectedProductType,
    },
    validationSchema: doorTypeFormValiationSchema,
    onSubmit: (values) => {
        dispatch(saveDoorTypeFormData(values));
        dispatch(updateRecord(values));
    },
  });

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
 } = formik;

  useEffect(() => {
    getDoorTypeOptions();
  }, []);

  const getDoorTypeOptions = async () => {
    const url = `/api/getdoortypes`;
    const list = await plainGetAPI(url);
    const { code, message, data } = list;
    if (code !== 200) {
      console.log(message);
    }

    setDoorTypeOptions(data);
  };

  const nextStep = () => {
    handleSubmit();
  }

  const handlePrevious = () => {
    // dispatch(resetAndRestartDoorForm());
    dispatch(setTopFormStep(doorFormStep));
  }

  return (
    <div className="main_wrap">
      <form className="main_form_wrap" onSubmit={handleSubmit}>
        <div className="form_step_wrap">
          <div className="form_step_item">
            <div className="form_head_cont">
              <h1 className="form_head">Door Type</h1>
              <div className="form_note">
                <p>
                  We maintain a vast in-stock inventory for standard doors, ensuring rapid shipping to meet your project deadlines. Custom doors are made to order for jobs and may have longer shipping times.
                </p>
              </div>
            </div>
            <div className="form_wrap">
              <div className="form_row">
                <div className="form-group">
                  <label className="lbl_main">
                    Please select Door Type.
                  </label>
                  <div className="form-check-img-wrap form-check-img-wrap-new">
                    {doorTypeOptions.length > 0 &&
                      doorTypeOptions.map((item, index) => {
                        return (
                          <div className="form-check" key={`door_type_${index}`}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="door_type"
                              id={`door_type${index}`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              key={"input-"+item+index}
                              value={item.option_label}
                              defaultChecked= {String(values.door_type).toLowerCase() === String(item.option_label).toLowerCase() }
                            />
                            <label
                              className="form-check-label form-check-img-item"
                              htmlFor={`door_type${index}`}
                            >
                              {item.option_label}
                              <img
                                  src={item.option_img}
                                  alt={item.option_label}
                                />  
                            </label>
                          </div>
                        );
                      })}
                    {touched.door_type &&
                    errors.door_type ? (
                      <div className="custom-invalid-feedback">
                        {errors.door_type}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_wrap btn_two_wrap d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-primary btn_sec w-auto"
            onClick={handlePrevious}
            disabled={doorIsLoading}
          >
            <i className="bi bi-chevron-left"></i> Back
          </button>
          <button
            type="button"
            onClick={nextStep}
            className="btn btn-primary btn_main w-auto"
            disabled={doorIsLoading}
          >
            Next <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default DoorType;
