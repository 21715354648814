export const getUTMParameters = () => {
    const params = new URLSearchParams(window.location.search);
    const utmParams = {};

    params.forEach((value, key) => {
        if (key.startsWith('utm_')) {
            utmParams[key] = value;
        } else if(key.startsWith('ad_')) {
            utmParams[key] = value;
        } else {
            //do nothing
        }
    });

    return utmParams;
}

export const fetchDimentions = (standardSize) => {
    const splitDimention = standardSize.split('x');
    const width = String(splitDimention[0]).replace('"w', '').trim();
    const height = String(splitDimention[1]).replace('"h', '').trim();
    return {
        'width': parseFloat(width),
        'height': parseFloat(height)
    };
  }