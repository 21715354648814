import React from 'react';

const FinanceLink = ({}) => {
  return (
    <div className='financing_int_Wrap'>
        <p className='financing_int_cont'>0% Interest Financing Available With Payments Starting as Low as $99/month</p>
        <a 
            href="https://www.enhancify.com/most-innovative-doors-llc" 
            target="_blank" 
            rel="noopener noreferrer"
            className='quote_btn'
            >
            Apply Now
        </a>
    </div>
  );
};

export default FinanceLink;
