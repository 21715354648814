import { configureStore } from "@reduxjs/toolkit";
import topFormReducer from './topFormSlice';
import windowFormReducer from './windowFormSlice';
import doorFormReducer from './doorFormSlice';

export const store = configureStore({
    reducer: {
        topForm: topFormReducer,
        windowForm: windowFormReducer,
        doorForm: doorFormReducer,
    }
});