import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { windowTypeFormValidationSchema } from "../../validations/validationScheme";
import { saveWindowTypeFormData, setFormStep, updateRecord, resetAndRestartWindowForm } from "../../redux/windowFormSlice";
import { setTopFormStep } from '../../redux/topFormSlice';
import { useEffect, useState } from "react";
import { plainGetAPI } from "../../utils/apiService";

const WindowType = () => {
  const dispatch = useDispatch();
  const { customerId, selectedProductType } = useSelector((store) => store.topForm);
  const { formStep, windowTypeFormData, isLoading } = useSelector((store) => store.windowForm);
 
  const [windowTypes, setWindowTypes] = useState([]);
  const formik = useFormik({
    initialValues: {
      id: customerId,
      window_type: windowTypeFormData?.window_type || "",
      form_step: formStep,
      product_type: selectedProductType,
    },
    validationSchema: windowTypeFormValidationSchema,
    onSubmit: (values) => {
        dispatch(saveWindowTypeFormData(values));
        dispatch(updateRecord(values));
    },
  });

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
 } = formik;

  useEffect(() => {
    getWindowTypeOptions();
  }, []);

  const getWindowTypeOptions = async () => {
    const url = `/api/getwindowtypes`;
    const list = await plainGetAPI(url);
    const { code, message, data } = list;
    if (code !== 200) {
      console.log(message);
    }

    setWindowTypes(data);
  };

  const nextStep = () => {
    handleSubmit();
  }

  const handlePrevious = () => {
    // dispatch(resetAndRestartWindowForm());
    dispatch(setTopFormStep(formStep));
  }

  return (
    <div className="main_wrap">
      <form className="main_form_wrap" onSubmit={handleSubmit}>
        <div className="form_step_wrap">
          {/* Step 2 */}
          <div className="form_step_item">
            <div className="form_head_cont">
              <h1 className="form_head">Window Type</h1>
              <div className="form_note">
                <p>
                  For custom windows <u>minimum</u> width is 36" &amp;{" "}
                  <u>maximum</u> width is 96". <u>Minimum</u> height is 36"
                  &amp; <u>maximum</u> is 60" for residential windows.
                </p>
                <p>
                  If you want sizes larger than our custom options for
                  residential or commercial applications, please choose "Extra
                  Large Motorized Window".
                </p>
              </div>
            </div>
            <div className="form_wrap">
              <div className="form_row">
                <div className="form-group">
                  <label className="lbl_main">
                    What type of window are you interested in?*
                  </label>
                  {windowTypes.length > 0 &&
                    windowTypes.map((item, index) => {
                      return (
                        <div className="form-check" key={`windowType-div-${index}`}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="window_type"
                            id={item}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            key={"input-"+item+index}
                            value={item}
                            defaultChecked={String(values.window_type).toLowerCase() === String(item).toLowerCase()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={item}
                          >
                            {item}
                          </label>
                          {touched.window_type && errors.window_type ? (
                            <div className="custom-invalid-feedback">{errors.window_type}</div>
                          ) : null}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_wrap btn_two_wrap d-flex justify-content-center">
          <button type="button" className="btn btn-primary btn_sec w-auto" onClick={handlePrevious} disabled={isLoading}>
            <i className="bi bi-chevron-left"></i> Back
          </button>
          <button type="button" onClick={nextStep} className="btn btn-primary btn_main w-auto" disabled={isLoading}>
            Next <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default WindowType;
