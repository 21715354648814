import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../axios/axiosConfig";
import axios from "axios";
// initial, windowType, standardWindowSize, customWindowSize, extraLargeWindowSize
const initialState = {
  topFormData: {},
  productTypeFormData: {},
  topFormStep: "initial",
  customerId: null,
  selectedProductType: null,
  topFormIsLoading: false,
  topFormDisableSubmit: false,
  topFormServerErrors: {},
  topFormStatusCode: null,
};

export const addCustomer = createAsyncThunk(
  "customer/add",
  async (record, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        BASE_URL + "/api/addcustomer",
        record
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "customer/update",
  async (record, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        BASE_URL + "/api/updatecustomer",
        record
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// initial, windowType, standardWindowSize, customWindowSize, extraLargeWindowSize, motorized, quantity
const getLastForm = (lastStep) => {
  switch (lastStep) {
    case "initial":
      return "topFormData";
    case "productType":
      return "productTypeFormData";
    default:
      return "topFormData";
  }
};

const topFormSlice = createSlice({
  name: "topForm",
  initialState,
  reducers: {
    saveTopFormData: (state, action) => {
      state.topFormData = action.payload;
    },
    saveProductTypeFormData: (state, action) => {
      state.productTypeFormData = action.payload;
    },

    setSelectedProductType: (state, action) => {
        state.selectedProductType = action.payload;
    },
    
    setTopFormStep: (state, action) => {
        switch(action.payload) {
            case 'productType':
                state.topFormStep = 'initial';
                return;
            case 'windowType': 
            case 'doorType':
                state.topFormStep = 'productType';
                return; 
            default:
                state.topFormStep = 'initial';
                return;
    
        }
    },

    resetAndRestartTopForm: (state, action) => {
        state.topFormData = {};
        state.productTypeFormData = {};
        state.topFormStep = "initial";
        state.customerId = null;
        state.selectedProductType = null;
        state.topFormIsLoading = false;
        state.topFormDisableSubmit = false;
        state.topFormServerErrors = {};
        state.topFormStatusCode = null;
    },
    
    setUnsetIsLoading: (state, action) => {
      state.topFormIsLoading = action.payload;
    }
  },
  extraReducers: (builder) => {
    // First Step Actions
    builder.addCase(addCustomer.pending, (state) => {
      state.topFormIsLoading = true;
    });

    builder.addCase(addCustomer.fulfilled, (state, action) => {
      const response = action.payload;
      state.topFormIsLoading = false;
      state.topFormData = response?.data;
      state.customerId = response?.data?.id;
      // If Product type was passed in URL then set the step accordingly
      // console.log(state.selectedProductType);   
      if(state.selectedProductType !== 'undefined' && String(state.selectedProductType).toLowerCase() === 'doors') {
        state.topFormStep = "doorMultiStepForm";
      } else if(state.selectedProductType !== 'undefined' && String(state.selectedProductType).toLowerCase() === 'windows') {
        state.topFormStep = "windowMultiStepForm";
      } else {
        state.topFormStep = "productType";
      }
      // If Product type was passed in URL then set the step accordingly
    });

    builder.addCase(addCustomer.rejected, (state, action) => {
      state.topFormIsLoading = false;
      state.error = action.error.message;
    });
    // First Step Actions

    // Update only actions
    builder.addCase(updateCustomer.pending, (state) => {
      state.topFormIsLoading = true;
    });

    builder.addCase(updateCustomer.fulfilled, (state, action) => {
      const lastForm = getLastForm(state.topFormStep);
      const response = action.payload;
      state.topFormIsLoading = false;
      state[lastForm] = response?.data;
      state.customerId = response?.data?.id;
      state.topFormStep = response?.data?.next_step;
    });

    builder.addCase(updateCustomer.rejected, (state, action) => {
      state.topFormIsLoading = false;
      state.error = action.error.message;
    });
    // Update only actions
  },
});

export const {
  saveTopFormData,
  saveProductTypeFormData,
  setTopFormStep,
  setUnsetIsLoading,
  setSelectedProductType,
  resetAndRestartTopForm,
} = topFormSlice.actions;
export default topFormSlice.reducer;
