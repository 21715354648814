import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../axios/axiosConfig";
import axios from "axios";
// initial, windowType, standardWindowSize, customWindowSize, extraLargeWindowSize
const initialState = {
  doorFormData: {},
  doorTypeFormData: {},
  doorSizeFormData: {},
  doorSwingFormData: {},
  doorColorFormData: {},
  doorGlassFormData: {},
  doorQuantityFormData: {},
  doorScreenFormData: {},
  doorHardwareFormData: {},
  doorFormStep: "doorType",
  doorFormId: null,
  doorFinalQuote: 0,
  doorIsLoading: false,
  doorDisableSubmit: false,
  doorServerErrors: {},
  doorStatusCode: null,
  doorFinalQuote: 0,
  doorQuotePdfLink: null,
};


export const updateRecord = createAsyncThunk(
  "record/update",
  async (record, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        BASE_URL + "/api/updaterecord",
        record
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// initial, windowType, standardWindowSize, customWindowSize, extraLargeWindowSize, motorized, quantity
const doorGetLastForm = (lastStep) => {
  switch (lastStep) {
    case "windowType":
      return "windowTypeFormData";
    default:
      return "doorType";
  }
};

const doorFormSlice = createSlice({
  name: "doorForm",
  initialState,
  reducers: {
    saveDoorTypeFormData: (state, action) => {
      state.doorTypeFormData = action.payload;
    },

    saveDoorSizeFormData: (state, action) => {
        state.doorSizeFormData = action.payload;
      },

    saveDoorSwingFormData: (state, action) => {
        state.doorSwingFormData = action.payload;
      },

    saveDoorColorFormData: (state, action) => {
    state.doorColorFormData = action.payload;
    },

    saveDoorGlassFormData: (state, action) => {
        state.doorGlassFormData = action.payload;
    },

    saveDoorHardwareFormData: (state, action) => {
        state.doorHardwareFormData = action.payload;
    },

    saveDoorQuantityFormData: (state, action) => {
        state.doorQuantityFormData = action.payload;
    },

    saveDoorScreenFormData: (state, action) => {
        state.doorScreenFormData = action.payload;
    },

    setDoorFinalQuote: (state, action) => {
        state.doorFinalQuote = action.payload.finalQuate;
        state.doorQuotePdfLink = action.payload.quotePdfLink;
    },
    
    setDoorFormStep: (state, action) => {
        switch(action.payload) {
            case 'doorSize':
                state.doorFormStep = 'doorType';
                return;
            case 'doorSwing':
                state.doorFormStep = 'doorSize';
                return;
            case 'doorColor':
                state.doorFormStep = 'doorSwing';
                return;
            case 'doorGlass':
                state.doorFormStep = 'doorColor';
                return;
            case 'doorHardware':
                state.doorFormStep = 'doorGlass';
                return;
            case 'doorQuantity':
                state.doorFormStep = 'doorHardware';
                return;
            case 'doorScreen':
                state.doorFormStep = 'doorQuantity';
                return;
            case 'doorQuote':
                state.doorFormStep = 'doorQuote';
                return;
            default:
                state.doorFormStep = 'doorType';
                return;
    
        }
    },
    
    resetAndRestartDoorForm: (state, action) => {
        state.doorFormData = {};
        state.doorTypeFormData = {};
        state.doorSizeFormData = {};
        state.doorSwingFormData = {};
        state.doorColorFormData = {};
        state.doorGlassFormData = {};
        state.doorQuantityFormData = {};
        state.doorScreenFormData = {};
        state.doorHardwareFormData = {};
        state.doorFormStep = "doorType";
        state.doorFormId = null;
        state.doorFinalQuote = 0;
        state.doorIsLoading = false;
        state.doorDisableSubmit = false;
        state.doorServerErrors = {};
        state.doorStatusCode = null;
        state.doorFinalQuote = 0;
        state.doorQuotePdfLink = null;

    },

    setUnsetDoorIsLoading: (state, action) => {
      state.doorIsLoading = action.payload;
    }
  },
  extraReducers: (builder) => {
    
    // Update only actions
    builder.addCase(updateRecord.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateRecord.fulfilled, (state, action) => {
      const lastForm = doorGetLastForm(state.formStep);
      const response = action.payload;
      state.doorIsLoading = false;
      state[lastForm] = response?.data;
      state.doorFormId = response?.data?.id;
      state.doorFormStep = response?.data?.next_step;
      console.log(state);
    });

    builder.addCase(updateRecord.rejected, (state, action) => {
      state.doorIsLoading = false;
      state.doorServerErrors = action.error.message;
    });
    // Update only actions
  },
});

export const {
  saveDoorTypeFormData,
  saveDoorSizeFormData,
  saveDoorSwingFormData,
  saveDoorColorFormData,
  saveDoorGlassFormData,
  saveDoorHardwareFormData,
  saveDoorQuantityFormData,
  saveDoorScreenFormData,
  setDoorFormStep,
  setDoorFinalQuote,
  resetAndRestartDoorForm,
  setUnsetDoorIsLoading
} = doorFormSlice.actions;
export default doorFormSlice.reducer;
