import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { doorGlassFormValidationSchema } from "../../validations/validationScheme";
import {
  saveDoorGlassFormData,
  setDoorFormStep,
  updateRecord,
} from "../../redux/doorFormSlice";
import { useEffect, useState } from "react";
import { plainGetAPI } from "../../utils/apiService";

const DoorGlass = () => {
  const dispatch = useDispatch();
  const { customerId, selectedProductType } = useSelector(
    (store) => store.topForm
  );
  const { doorFormStep, doorGlassFormData, doorIsLoading } = useSelector(
    (store) => store.doorForm
  );

  const [doorGlassTypes, setDoorGlassTypes] = useState([]);
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: customerId,
      glass_type: (doorGlassFormData?.glass_type) ? doorGlassFormData.glass_type : (doorGlassTypes.length === 1 ? doorGlassTypes[0].option_label : ""),
      form_step: doorFormStep,
      product_type: selectedProductType,
    },
    validationSchema: doorGlassFormValidationSchema,
    onSubmit: (values) => {
      dispatch(saveDoorGlassFormData(values));
      dispatch(updateRecord(values));
    },
  });

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    formik;

  useEffect(() => {
    getDoorGlassTypes();
  }, []);

  const getDoorGlassTypes = async () => {
    const url = `/api/getdoorglasstypes?id=${customerId}`;
    const list = await plainGetAPI(url);
    const { code, message, data } = list;
    if (code !== 200) {
      console.log(message);
    }

    setDoorGlassTypes(data);
  };

  const nextStep = () => {
    handleSubmit();
  };

  const handlePrevious = () => {
    dispatch(setDoorFormStep(doorFormStep));
  };

  return (
    <div className="main_wrap">
      <form className="main_form_wrap" onSubmit={handleSubmit}>
        <div className="form_step_wrap">
          <div className="form_step_item">
            <div className="form_head_cont">
              <h1 className="form_head">Glass Type</h1>
              {/* <div className="form_note">
                Door Glass type content goes here
              </div> */}
            </div>
            <div className="form_wrap">
              <div className="form_row">
                <div className="form-group width_half">
                  <label className="form-label">
                    Choose Glass Type*
                  </label>
                  <div className="form-check-img-wrap form-check-img-wrap-new">
                  {doorGlassTypes.length > 0 &&
                    doorGlassTypes.map((item, index) => {
                      return (
                        <div
                          className="form-check"
                          key={`door_glass_type_${index}`}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="glass_type"
                            id={`glass_type${index}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            key={"input-" + item + index}
                            value={item.option_label}
                            defaultChecked={
                              (String(values.glass_type).toLowerCase() ===
                              String(item.option_label).toLowerCase()) || doorGlassTypes.length === 1
                            }
                            
                          />
                          <label
                            className="form-check-label form-check-img-item"
                            htmlFor={`glass_type${index}`}
                          >
                            {item.option_label}
                            <img
                              src={item.option_img}
                              alt={item.option_label}
                            />
                          </label>
                        </div>
                      );
                    })}
                  {touched.glass_type && errors.glass_type ? (
                    <div className="custom-invalid-feedback">
                      {errors.glass_type}
                    </div>
                  ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_wrap btn_two_wrap d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-primary btn_sec w-auto"
            onClick={handlePrevious}
            disabled={doorIsLoading}
          >
            <i className="bi bi-chevron-left"></i> Back
          </button>
          <button
            type="button"
            onClick={nextStep}
            className="btn btn-primary btn_main w-auto"
            disabled={doorIsLoading}
          >
            Next <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default DoorGlass;
