
import httpInstance from '../axios/axiosConfig';

export const plainGetAPI = async(url) => {
    try {
        const res = await httpInstance.get(url);
        const data = await res.data
        return data;
    } catch(error) {
        console.log(error);
        const responseStatus = error?.response?.status;
        const msg = error?.response?.data?.message;
        switch(responseStatus) {
            case 500:
                console.log("500 error check server log");
                // toast.error("Error in processing your request, please try after some time.", {
                //     position: "top-right",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //   });
            return;
            default:
                console.log("Error at server level please check the log");
                // toast.error(msg, {
                //     position: "top-right",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //   });
            return
        }
    } finally {
        // do nothing
    }
};