import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { productTypeFormValidationSchema } from "../validations/validationScheme";
import { saveProductTypeFormData, updateCustomer, setTopFormStep, setSelectedProductType } from "../redux/topFormSlice";
import { useEffect, useState } from "react";
import { plainGetAPI } from "../utils/apiService";

const ProductType = () => {
  const dispatch = useDispatch();
  const { customerId, topFormStep, productTypeFormData, topFormIsLoading } = useSelector((store) => store.topForm);
  const [productTypes, setProductTypes] = useState([]);
  const formik = useFormik({
    initialValues: {
      id: customerId,
      product_type: productTypeFormData?.product_type || "",
      top_form_step: topFormStep
    },
    validationSchema: productTypeFormValidationSchema,
    onSubmit: (values) => {
        // console.log(values);
        dispatch(saveProductTypeFormData(values));
        dispatch(setSelectedProductType(values.product_type));
        dispatch(updateCustomer(values));
    },
  });

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
 } = formik;

  useEffect(() => {
    getProductTypes();
  }, []);

  const getProductTypes = async () => {
    const url = `/api/getproducttypes`;
    const list = await plainGetAPI(url);
    const { code, message, data } = list;
    if (code !== 200) {
      console.log(message);
    }

    setProductTypes(data);
  };

  const nextStep = () => {
    handleSubmit();
  }

  const handlePrevious = () => {
    dispatch(setTopFormStep(topFormStep));
  }

  return (
    <div className="main_wrap">
      <form className="main_form_wrap" onSubmit={handleSubmit}>
        <div className="form_step_wrap">
          {/* Step 2 */}
          <div className="form_step_item">
            <div className="form_head_cont">
              <h1 className="form_head">Select Product</h1>
              {/* <div className="form_note">
                 some text will come here
              </div> */}
            </div>
            <div className="form_wrap">
              <div className="form_row">
                <div className="form-group">
                  <label className="lbl_main">
                    Which product are you interested in?
                  </label>
                  {productTypes.length > 0 &&
                    productTypes.map((item, index) => {
                      return (
                        <div className="form-check" key={`product-div-${index}`}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="product_type"
                            id={item}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            key={"input-"+item+index}
                            value={item}
                            defaultChecked={String(values.product_type).toLowerCase() === String(item).toLowerCase()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={item}
                          >
                            {item}
                          </label>
                        </div>
                      );
                    })}
                    {touched.product_type && errors.product_type ? (
                            <div className="custom-invalid-feedback">{errors.product_type}</div>
                          ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_wrap btn_two_wrap d-flex justify-content-center">
          <button type="button" className="btn btn-primary btn_sec w-auto" onClick={handlePrevious} disabled={topFormIsLoading}>
            <i className="bi bi-chevron-left"></i> Back
          </button>
          <button type="button" onClick={nextStep} className="btn btn-primary btn_main w-auto" disabled={topFormIsLoading}>
            Next <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProductType;
