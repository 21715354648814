import React, { useState } from 'react';
import PrimaryForm from './components/PrimaryForm';
import { useSelector } from 'react-redux';
import ProductType from './components/ProductType';
import WindowMultiStepForm from './WindowMultiStepForm';
import DoorMultiStepForm from './DoorMultiStepForm';

const TopLevelSteps = () => {
    const {customerId, topFormStep} = useSelector((store) => store.topForm);
    // console.log(topFormStep);
    switch (topFormStep) {
        case 'initial':
          return <PrimaryForm />
        case 'productType':
            return <ProductType />
        case 'windowMultiStepForm':
            return <WindowMultiStepForm />
        case 'doorMultiStepForm':
            return <DoorMultiStepForm />
        default:
          return <div>Invalid step</div>;
      }
}

export default TopLevelSteps;