// header.js
import React from 'react';

const Footer = ({ nextStep, handleChange, values }) => {
  return (
    <div>
      
    </div>
  );
};

export default Footer;
