import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { quantityFormValidationSchema } from "../../validations/validationScheme";
import { resetAndRestartWindowForm, saveQuantityFormData, setFormStep, updateRecord } from "../../redux/windowFormSlice";

const ExtraLargeWindowSize = () => {
  const dispatch = useDispatch();
  
  const clearAllAndRestart = () => {
    dispatch(resetAndRestartWindowForm());
  }
  
  return (
    <div className="main_wrap">
        <div className="form_step_wrap">
        <div className="form_step_item">
            <div className="form_head_cont">
              <h1 className="form_head">Call For Pricing</h1>
              <h4 className="form_subhead">Contact Us</h4>
              <div className="form_note">
                <p>Our team is standing by 9am - 5pm CST Monday - Friday to give you an instant quote for a custom door or window. We have many options, suitable for any project. </p>
                <p>We will call you shortly or you can reach us at (888) 472-5793.</p>
                <p>Thank you!</p>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_wrap btn_two_wrap d-flex justify-content-center">
          <button type="button" className="btn btn-primary btn_sec w-auto" onClick={clearAllAndRestart}>
            <i className="bi bi-chevron-left"></i> Get New Quote
          </button>
          {/* <button
            type="button"
            onClick={nextStep}
            className="btn btn-primary btn_main w-auto"
          >
            Next <i className="bi bi-chevron-right"></i>
          </button> */}
        </div>
    </div>
  );
};

export default ExtraLargeWindowSize;
