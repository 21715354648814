import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { doorHardwareFormValidationSchema } from "../../validations/validationScheme";
import {
  saveDoorHardwareFormData,
  setDoorFormStep,
  updateRecord,
} from "../../redux/doorFormSlice";
import { useEffect, useState } from "react";
import { plainGetAPI } from "../../utils/apiService";

const DoorHardware = () => {
  const dispatch = useDispatch();
  const { customerId, selectedProductType } = useSelector(
    (store) => store.topForm
  );
  const { doorFormStep, doorHardwareFormData, doorIsLoading } = useSelector(
    (store) => store.doorForm
  );

  const [doorHardwareTypes, setDoorHardwareTypes] = useState([]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: customerId,
      hardware: doorHardwareFormData?.hardware ? doorHardwareFormData.hardware: (doorHardwareTypes.length === 1 ? doorHardwareTypes[0].option_label : ""),
      form_step: doorFormStep,
      product_type: selectedProductType,
    },
    validationSchema: doorHardwareFormValidationSchema,
    onSubmit: (values) => {
      dispatch(saveDoorHardwareFormData(values));
      dispatch(updateRecord(values));
    },
  });

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    formik;

  useEffect(() => {
    getDoorHardwareTypes();
  }, []);

  const getDoorHardwareTypes = async () => {
    const url = `/api/getdoorhardwaretypes?id=${customerId}`;
    const list = await plainGetAPI(url);
    const { code, message, data } = list;
    if (code !== 200) {
      console.log(message);
    }

    setDoorHardwareTypes(data);
  };

  const nextStep = () => {
    handleSubmit();
  };

  const handlePrevious = () => {
    dispatch(setDoorFormStep(doorFormStep));
  };

  return (
    <div className="main_wrap">
      <form className="main_form_wrap" onSubmit={handleSubmit}>
        <div className="form_step_wrap">
          <div className="form_step_item">
            <div className="form_head_cont">
              <h1 className="form_head">Hardware</h1>
              {/* <div className="form_note">
                Door Hardware content goes here
              </div> */}
            </div>
            <div className="form_wrap">
              <div className="form_row">
                <div className="form-group width_half">
                  <label className="form-label">
                    Choose Hardware*
                  </label>
                  <div className="form-check-img-wrap form-check-img-wrap-new">
                  {doorHardwareTypes.length > 0 &&
                    doorHardwareTypes.map((item, index) => {
                      return (
                        <div
                          className="form-check"
                          key={`door_hardware_type_${index}`}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="hardware"
                            id={`hardware${index}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            key={"input-" + item + index}
                            value={item.option_label}
                            defaultChecked={
                              (String(values.hardware).toLowerCase() ===
                              String(item.option_label).toLowerCase()) || doorHardwareTypes.length === 1
                            }
                          />
                          <label
                            className="form-check-label form-check-img-item"
                            htmlFor={`hardware${index}`}
                          >
                            {item.option_label}
                            <img
                              src={item.option_img}
                              alt={item.option_label}
                            />
                          </label>
                        </div>
                      );
                    })}
                  {touched.hardware && errors.hardware ? (
                    <div className="custom-invalid-feedback">
                      {errors.hardware}
                    </div>
                  ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_wrap btn_two_wrap d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-primary btn_sec w-auto"
            onClick={handlePrevious}
            disabled={doorIsLoading}
          >
            <i className="bi bi-chevron-left"></i> Back
          </button>
          <button
            type="button"
            onClick={nextStep}
            className="btn btn-primary btn_main w-auto"
            disabled={doorIsLoading}
          >
            Next <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default DoorHardware;
