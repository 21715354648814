import React, { useEffect } from "react";
import ReactPixel from 'react-facebook-pixel';


const FacebookPixel = () => {
  useEffect(() => {
    ReactPixel.init('869308524978002');
    ReactPixel.pageView();
  }, []);

  return null;
};

export default FacebookPixel;
