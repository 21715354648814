// MultiStepForm.js
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DoorType from './components/door/DoorType';
import DoorSize from './components/door/DoorSize';
import DoorSwing from './components/door/DoorSwing';
import DoorColor from './components/door/DoorColor';
import DoorGlass from './components/door/DoorGlass';
import DoorHardware from './components/door/DoorHardware';
import DoorQuantity from './components/door/DoorQuantity';
import DoorQuote from './components/door/DoorQuote';
import NonStandardDoorType from './components/door/NonStandardDoorType';
import DoorScreen from './components/door/DoorScreen';
// import Quantity from './components/window/Quantity';
// import FinalQuoteScreen from './components/window/FinalQuoteScreen';

const DoorMultiStepForm = () => {
  const {doorFormStep} = useSelector((store) => store.doorForm);
//   console.log(doorFormStep);
  switch (doorFormStep) {
    case 'doorType':
      return <DoorType />
    case 'nonStandardDoorType':
        return <NonStandardDoorType />
    case 'doorSize':
        return <DoorSize />
    case 'doorSwing':
        return <DoorSwing />
    case 'doorColor':
        return <DoorColor />
    case 'doorGlass':
        return <DoorGlass />
    case 'doorHardware':
        return <DoorHardware />
    case 'doorQuantity':
        return <DoorQuantity />
    case 'doorScreen':
        return <DoorScreen />
    case 'doorQuote':
        return <DoorQuote />
    default:
      return <div>Invalid step</div>;
  }
};

export default DoorMultiStepForm;
