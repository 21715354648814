import './App.css';
import Header from './components/header';
import Footer from './components/footer';
import FacebookPixel from './Analytics/FacebookPixel';
import TopLevelSteps from './TopLevelSteps';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import NotFound from './components/NotFound';

const router = createBrowserRouter([
  {
    path:"*",
    element: <NotFound />
  },
  {
    path: "/",
    element: <TopLevelSteps />
  },
]);

function App() {
  
  return (
    <div className="App">
      <Header />
      <FacebookPixel />
      <RouterProvider router={router}>
      </RouterProvider>
      <Footer />
    </div>
  );
}

export default App;
