import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import * as pdfjs1 from 'pdfjs-dist/webpack';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url,
// ).toString();
pdfjs1.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;
console.log(pdfjs1.GlobalWorkerOptions.workerSrc);



function PdfViewer({fileUrl}) {
  const [numPages, setNumPages] = React.useState(null);

  // Function to handle the successful loading of a PDF document
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div>
      <Document
        file={fileUrl} // Replace with your URL
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} renderTextLayer={false} />
        ))}
      </Document>
    </div>
  );
}

export default PdfViewer;