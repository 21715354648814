import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import { useDispatch, useSelector } from "react-redux";
import { plainGetAPI } from "../../utils/apiService";
import { resetAndRestartTopForm } from "../../redux/topFormSlice";
import {
  setDoorFinalQuote,
  resetAndRestartDoorForm,
} from "../../redux/doorFormSlice";
import { resetAndRestartWindowForm } from "../../redux/windowFormSlice";
import PdfViewer from "../PdfViewer";
import pdfIcon from "../../download-pdf.png";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import FinanceLink from "../FinanceLink";

const DoorQuote = () => {
  const dispatch = useDispatch();
  const { customerId, selectedProductType } = useSelector(
    (store) => store.topForm
  );
  const { doorFormStep, doorIsLoading, doorFinalQuote, doorQuotePdfLink } =
    useSelector((store) => store.doorForm);

  const {} = useSelector((store) => store.windowForm);

  useEffect(() => {
    // console.log(formData, windowTypeFormData, standardWindowSizeFormData, customWindowSizeFormData, motorizedFormData, quantityFormData, finalQuote );
    calculateDoorFinalQuote();
  }, []);

  const calculateDoorFinalQuote = async () => {
    let targetUrl = `/api/getdoorfinalquote?id=${customerId}`;

    const response = await plainGetAPI(targetUrl);

    const { code, message, data } = response;
    // if (code !== 200) {
    //   console.log(message);
    // }
    if (code === 200) {
        try {
          ReactPixel.track('CustomizeProduct', {content_type: "Doors Calculator"});
          console.log('Lead Event Tracked');
        } catch(error) {
          console.log(error, 'Error in Lead tracking');
        }

      dispatch(setDoorFinalQuote(data));
      // Trigger Facebook Lead Event
    } else {
      // dispatch(setFinalQuote(estimated_cost));
      console.log("error in fetching the final quote");
    }

    // console.log(data);
  };

  const clearAllAndRestart = () => {
    dispatch(resetAndRestartTopForm());
    dispatch(resetAndRestartDoorForm());
    dispatch(resetAndRestartWindowForm());
  };

  return (
    <div className="main_wrap">
      <div className="form_step_wrap">
        <div className="form_step_item">
          <div className="form_head_cont">
            <h1 className="form_head">Total Cost</h1>
            <h4 className="form_subhead">
              {doorFinalQuote === 0 ? "Please Wait.." : doorFinalQuote}
            </h4>
            {doorFinalQuote !== 0 ? <FinanceLink /> : null }
            <div className="form_note">
              <p>
                We will email you a copy of your estimate and our team will
                reach out shortly for any questions you may have.
              </p>
              <p>
                Our team is standing by 9am - 5pm CST Monday - Friday. We have
                many options, suitable for any project.
              </p>
              <p>
                You can reach us at (888) 472-5793 or visit our website at
                https://mostinnovativedoors.com.
              </p>
              <p>Thank you!</p>
            </div>
          </div>
        </div>
      </div>
      <div className="btn_wrap btn_two_wrap d-flex justify-content-center align-items-start">
        <button
          type="button"
          className="btn btn-primary quote_btn mx-2"
          onClick={clearAllAndRestart}
        >
          <i className="bi bi-chevron-left"></i> Get New Quote
        </button>
        <div className="down_pdf_wrap mx-2">
        <a
          href={doorQuotePdfLink}
          target="_blank"
          rel="noreferrer"
          className="download_btn download_black_btn"
        >
          <img src={pdfIcon} alt="Download PDF" className="pdf-icon" /> Download
          PDF
        </a>
        </div>
      </div>
      <PdfViewer fileUrl={doorQuotePdfLink} />
    </div>
  );
};

export default DoorQuote;
