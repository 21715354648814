import * as Yup from 'yup';

const phoneRegExp = /^(\+1\s?)?(\d{3}|\(\d{3}\))[-\s.]?\d{3}[-\s.]?\d{4}$/;
const zipRegExp = /^\d{5}(-\d{4})?$/;

export const formValidationSchema = Yup.object({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone number is required'),
    zipcode: Yup.string()
    .matches(zipRegExp, 'Zip code is not valid')
    .required('Zip code is required'),
    what_best_describes_you: Yup.string().required('This field is required'),
    how_did_you_hear_about_us: Yup.string().required('This Field is required'),
});

export const windowTypeFormValidationSchema = Yup.object({
    window_type: Yup.string().required('Window Type is required'), 
});

export const standardSizeFormValidationSchema = Yup.object({
    standard_size: Yup.string().required('Standard Size is required'), 
});

export const customSizeFormValidationSchema = Yup.object({
    custom_width: Yup.number().min(36, 'Please enter a number between 36 and 96').max(96, 'Please enter a number between 36 and 96').required('Width is required'), 
    custom_height: Yup.number().min(36, 'Please enter a number between 36 and 60').max(60, 'Please enter a number between 36 and 60').required('Height is required'), 
});

export const motorizedFormValidationSchema = Yup.object({
    is_motorized: Yup.string().required('This is required'), 
});

export const windowSillFormValiationSchema = Yup.object({
    window_sill: Yup.string().required('Window Sill is required'),
});

export const windowScreenFormValidationSchema = Yup.object({
    window_screen: Yup.string().required('Window Screen is required'),
});

export const windowColorFormValiationSchema = Yup.object({
    window_color: Yup.string().required('Window Color is required'),
    custom_color: Yup.string().matches(/^\d+$/, "Please enter a valid 4 digit RAL number").when('window_color', {
        is: (value) => value === 'Custom Color',
        then: (windowColorFormValiationSchema) => windowColorFormValiationSchema.min(4, 'Please enter a valid 4 digit RAL number').max(4, 'Please enter a valid 4 digit RAL number').required('Custom Color is required'),
        otherwise: (windowColorFormValiationSchema) => windowColorFormValiationSchema
    }),
});

export const quantityFormValidationSchema = Yup.object({
    quantity: Yup.string().required('Quantity is required'), 
});


export const productTypeFormValidationSchema = Yup.object({
    product_type: Yup.string().required('Product type is required'),
});

export const doorTypeFormValiationSchema = Yup.object({
    door_type: Yup.string().required('Door Type is required'),
});

export const doorSizeFormValidationSchema = Yup.object({
    width: Yup.string().required('Width is required'),
    height: Yup.string().required('Height is required'),
    number_of_panels: Yup.string().required('Number of Panels is required'),
});

export const doorSwingFormValidationSchema = Yup.object({
    swing_door_position: Yup.string().required('Pedestrian Door Location is required'),
    choose_fold_direction: Yup.string().required('Fold Direction is required'),
});

export const doorColorFormValidationSchema = Yup.object({
    exterior_color: Yup.string().required('Color is required'),
    // interior_color: Yup.string().when('inerior_finish_same_as_exterior', {
    //     is: (value) => String(value).toLowerCase() === 'no',
    //     then: (doorColorFormValidationSchema) => doorColorFormValidationSchema.required('Interior Color is required'),
    //     otherwise: (doorColorFormValidationSchema) => doorColorFormValidationSchema
    // }),
});

export const doorGlassFormValidationSchema = Yup.object({
    glass_type: Yup.string().required('Door Glass Type is required'),
});

export const doorHardwareFormValidationSchema = Yup.object({
    hardware: Yup.string().required('Door Hardware is required'),
});

export const doorQuantityFormValidationSchema = Yup.object({
    quantity: Yup.string().required('Quantity is required'), 
});

export const doorScreenFormValidationSchema = Yup.object({
    door_screen: Yup.string().required('Quantity is required'), 
});