import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { doorSizeFormValidationSchema } from "../../validations/validationScheme";
import {
  saveDoorSizeFormData,
  setDoorFormStep,
  updateRecord,
} from "../../redux/doorFormSlice";
import { useEffect, useState } from "react";
import { plainGetAPI } from "../../utils/apiService";

const DoorSize = () => {
  const dispatch = useDispatch();
  const { customerId, selectedProductType } = useSelector(
    (store) => store.topForm
  );
  const { doorFormStep, doorSizeFormData, doorIsLoading } = useSelector(
    (store) => store.doorForm
  );

  const [doorWidthOptions, setDoorWidthOptions] = useState([]);
  const [doorHeightOptions, setDoorHeightOptions] = useState([]);
  const [doorPanelOptions, setDoorPanelOptions] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: customerId,
      width: doorSizeFormData?.width || "",
      height: doorSizeFormData?.height ? doorSizeFormData.height : (doorHeightOptions.length === 1 ? doorHeightOptions[0] : ""),
      number_of_panels: doorSizeFormData?.number_of_panels || "",
      form_step: doorFormStep,
      product_type: selectedProductType,
    },
    validationSchema: doorSizeFormValidationSchema,
    onSubmit: (values) => {
      dispatch(saveDoorSizeFormData(values));
      dispatch(updateRecord(values));
    },
  });

  const { values, errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched } =
    formik;

  useEffect(() => {
    getDoorWidthOptions();
    getDoorHeightOptions();
  }, []);

  useEffect(() => {
    getNumberOfPanelsOptions();
  }, [values.width, values.height]);

  const getDoorWidthOptions = async () => {
    const url = `/api/getdoorwidthoptions?id=${customerId}`;
    const list = await plainGetAPI(url);
    const { code, message, data } = list;
    if (code !== 200) {
      console.log(message);
    }

    setDoorWidthOptions(data);
  };

  const getDoorHeightOptions = async () => {
    const url = `/api/getdoorheightoptions?id=${customerId}`;
    const list = await plainGetAPI(url);
    const { code, message, data } = list;
    if (code !== 200) {
      console.log(message);
    }
    setDoorHeightOptions(data);
  };

  const getNumberOfPanelsOptions = async () => {
    setDoorPanelOptions([]);
    if (values.width === "" || values.height === "") {
      return false;
    }
    
    const url = `/api/getdoornumberofpanels?id=${customerId}&width=${values.width}&height=${values.height}`;
    const list = await plainGetAPI(url);
    const { code, message, data } = list;
    if (code !== 200) {
      console.log(message);
    }

    setDoorPanelOptions(data);
  };

  const nextStep = () => {
    handleSubmit();
  };

  const handlePrevious = () => {
    dispatch(setDoorFormStep(doorFormStep));
  };

  return (
    <div className="main_wrap">
      <form className="main_form_wrap" onSubmit={handleSubmit}>
        <div className="form_step_wrap">
          <div className="form_step_item">
            <div className="form_head_cont">
              <h1 className="form_head">Size & Panels</h1>
              <div className="form_note">
              Note: The nominal size of the doors are actually a 1/2" smaller in width and height. For (Example: 144" W x 96" H door, the net size will be 143.5" W x 95.5H)
              </div>
            </div>
            <div className="form_wrap">
              <div className="form_row">
                <div className="form-group width_half">
                  <label className="form-label">Width*</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="width"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.width}
                  >
                    <option value="">Select Width</option>
                    {doorWidthOptions.length &&
                      doorWidthOptions.map((item, index) => {
                        return (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        );
                      })}
                  </select>
                  {touched.width && errors.width ? (
                    <div className="custom-invalid-feedback">
                      {errors.width}
                    </div>
                  ) : null}
                </div>
                <div className="form-group width_half">
                  <label className="form-label">Height*</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="height"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.height}
                  >
                    {/* <option value="">Select Height</option> */}
                    {doorHeightOptions.length &&
                      doorHeightOptions.map((item, index) => {
                        return (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        );
                      })}
                  </select>
                  {touched.height && errors.height ? (
                    <div className="custom-invalid-feedback">
                      {errors.height}
                    </div>
                  ) : null}
                </div>
              </div>
              { doorPanelOptions.length > 0
              ? (<div className="form_row">
                <div className="form-group width_full mt-3">
                  <label className="form-label">Choose Number Of Panels*</label>
                  <div className="form-check-img-wrap form-check-img-wrap-new">
                  {doorPanelOptions.map((item, index) => {
                      return (
                        <div className="form-check" key={`door_panel_${index}`}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="number_of_panels"
                            id={`number_of_panels${index}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            key={"input-" + item + index}
                            value={item.option_label}
                            defaultChecked={
                              String(values.number_of_panels).toLowerCase() ===
                              String(item.option_label).toLowerCase()
                            }
                          />
                          <label
                            className="form-check-label form-check-img-item"
                            htmlFor={`number_of_panels${index}`}
                          >
                            {item.option_label} Panels
                            <img
                              src={item.option_img}
                              alt={item.option_label}
                            />
                          </label>
                        </div>
                      );
                    })}
                  {touched.number_of_panels && errors.number_of_panels ? (
                    <div className="custom-invalid-feedback">
                      {errors.number_of_panels}
                    </div>
                  ) : null}
                  </div>
                </div>
              </div>)
              : null
              }
              
            </div>
          </div>
        </div>
        <div className="btn_wrap btn_two_wrap d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-primary btn_sec w-auto"
            onClick={handlePrevious}
            disabled={doorIsLoading}
          >
            <i className="bi bi-chevron-left"></i> Back
          </button>
          <button
            type="button"
            onClick={nextStep}
            className="btn btn-primary btn_main w-auto"
            disabled={doorIsLoading}
          >
            Next <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default DoorSize;
