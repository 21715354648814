import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { doorColorFormValidationSchema } from "../../validations/validationScheme";
import {
  saveDoorColorFormData,
  setDoorFormStep,
  updateRecord,
} from "../../redux/doorFormSlice";
import { useEffect, useState } from "react";
import { plainGetAPI } from "../../utils/apiService";

const DoorColor = () => {
  const dispatch = useDispatch();
  const { customerId, selectedProductType } = useSelector(
    (store) => store.topForm
  );
  const { doorFormStep, doorColorFormData, doorIsLoading } = useSelector(
    (store) => store.doorForm
  );

  const [doorColorOptions, setDoorColorOptions] = useState([]);
  const interiorColorFlag = ["yes", "no"];

  const formik = useFormik({
    initialValues: {
      id: customerId,
      exterior_color: doorColorFormData?.exterior_color || "",
      //   inerior_finish_same_as_exterior:
      //     doorColorFormData?.inerior_finish_same_as_exterior || "yes",
      //   interior_color: doorColorFormData?.interior_color || "",
      form_step: doorFormStep,
      product_type: selectedProductType,
    },
    validationSchema: doorColorFormValidationSchema,
    onSubmit: (values) => {
      dispatch(saveDoorColorFormData(values));
      dispatch(updateRecord(values));
    },
  });

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    formik;

  useEffect(() => {
    getDoorColorOptions();
  }, []);

  const getDoorColorOptions = async () => {
    const url = `/api/getdoorcoloroptions?id=${customerId}`;
    const list = await plainGetAPI(url);
    const { code, message, data } = list;
    if (code !== 200) {
      console.log(message);
    }

    setDoorColorOptions(data);
  };

  const nextStep = () => {
    handleSubmit();
  };

  const handlePrevious = () => {
    dispatch(setDoorFormStep(doorFormStep));
  };

  return (
    <div className="main_wrap">
      <form className="main_form_wrap" onSubmit={handleSubmit}>
        <div className="form_step_wrap">
          <div className="form_step_item">
            <div className="form_head_cont">
              <h1 className="form_head">Color & Finish</h1>
              <div className="form_note">
                Select a color for your door. Standard colors are available as a
                single color inside and out.
              </div>
            </div>
            <div className="form_wrap">
              <div className="form_row">
                <div className="form-group width_full">
                  <label className="form-label">
                  Choose Color*
                  </label>
                  <div className="form-check-img-wrap form-check-img-wrap-new">
                    {doorColorOptions.length > 0 &&
                      doorColorOptions.map((item, index) => {
                        return (
                          <div
                            className="form-check"
                            key={`exterior_color_${index}`}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="exterior_color"
                              id={`exterior_color${index}`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              key={"input-" + item + index}
                              value={item.option_label}
                              defaultChecked={
                                String(values.exterior_color).toLowerCase() ===
                                String(item.option_label).toLowerCase()
                              }
                            />
                            <label
                              className="form-check-label form-check-img-item"
                              htmlFor={`exterior_color${index}`}
                            >
                              {/* {item.option_label} */}
                              <img
                                src={item.option_img}
                                alt={item.option_label}
                              />
                            </label>
                          </div>
                        );
                      })}
                    {touched.exterior_color && errors.exterior_color ? (
                      <div className="custom-invalid-feedback">
                        {errors.exterior_color}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {/* <div className="form_row">
                <div className="form-group width_full">
                  <label className="form-label">
                    Interior Finish Same As Exterior?
                  </label>
                  {interiorColorFlag.length > 0 &&
                    interiorColorFlag.map((item, index) => {
                      return (
                        <div
                          className="form-check"
                          key={`interior_color_flag_${index}`}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inerior_finish_same_as_exterior"
                            id={`interior_color_flag${index}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            key={"input-" + item + index}
                            value={item}
                            defaultChecked={
                              String(
                                values.inerior_finish_same_as_exterior
                              ).toLowerCase() === String(item).toLowerCase()
                            }
                          />
                          <label
                            className="form-check-label form-check-img-item"
                            htmlFor={`interior_color_flag${index}`}
                          >
                            {String(item).toUpperCase()}
                          </label>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="form_row">
                {String(
                  values.inerior_finish_same_as_exterior
                ).toLowerCase() === "no" ? (
                  <div className="form-group width_full">
                    <label className="form-label">
                      Choose Interior Color and Finish*
                    </label>
                    <div className="form-check-img-wrap form-check-img-wrap-new">
                      {doorColorOptions.length > 0 &&
                        doorColorOptions.map((item, index) => {
                          return (
                            <div
                              className="form-check"
                              key={`interior_color_${index}`}
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="interior_color"
                                id={`interior_color${index}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                key={"input-interior-color" + item + index}
                                value={item.option_label}
                                defaultChecked={
                                  String(
                                    values.interior_color
                                  ).toLowerCase() ===
                                  String(item.option_label).toLowerCase()
                                }
                              />
                              <label
                                className="form-check-label form-check-img-item"
                                htmlFor={`interior_color${index}`}
                              >
                                {item.option_label}
                                <img
                                  src={item.option_img}
                                  alt={item.option_label}
                                />
                              </label>
                            </div>
                          );
                        })}
                      {touched.interior_color && errors.interior_color ? (
                        <div className="custom-invalid-feedback">
                          {errors.interior_color}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div> */}
            </div>
          </div>
        </div>
        <div className="btn_wrap btn_two_wrap d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-primary btn_sec w-auto"
            onClick={handlePrevious}
            disabled={doorIsLoading}
          >
            <i className="bi bi-chevron-left"></i> Back
          </button>
          <button
            type="button"
            onClick={nextStep}
            className="btn btn-primary btn_main w-auto"
            disabled={doorIsLoading}
          >
            Next <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default DoorColor;
