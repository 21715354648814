import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { doorScreenFormValidationSchema } from "../../validations/validationScheme";
import { saveDoorScreenFormData, setDoorFormStep, updateRecord } from "../../redux/doorFormSlice";
import { useEffect, useState } from "react";
import doorGif from "../../door-gif.gif";
import { plainGetAPI } from "../../utils/apiService";

const DoorScreen = () => {
  const dispatch = useDispatch();
  const { customerId, selectedProductType } = useSelector((store) => store.topForm);
  const { doorFormStep, doorScreenFormData, doorIsLoading } = useSelector((store) => store.doorForm);
 
  const [screenOptions, setScreenOption] = useState(['yes', 'no']);
  const [doorScreenGif, setDoorScreenGif] = useState(null);
  const formik = useFormik({
    initialValues: {
      id: customerId,
      door_screen: doorScreenFormData?.door_screen || "no",
      form_step: doorFormStep,
      product_type: selectedProductType,
    },
    validationSchema: doorScreenFormValidationSchema,
    onSubmit: (values) => {
        dispatch(saveDoorScreenFormData(values));
        dispatch(updateRecord(values));
    },
  });

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
 } = formik;

 useEffect(()=>{
  getDoorScreenGif();
 }, []);

  const nextStep = () => {
    handleSubmit();
  }

  const handlePrevious = () => {
    // dispatch(resetAndRestartDoorForm());
    dispatch(setDoorFormStep(doorFormStep));
  }

  const getDoorScreenGif = async () => {
    const url = `/api/getdoorscreengif`;
    const list = await plainGetAPI(url);
    const { code, message, data } = list;
    if (code !== 200) {
      console.log(message);
    }

    const {imageUrl} = data;
    setDoorScreenGif(imageUrl);
  }

  return (
    <div className="main_wrap">
      <form className="main_form_wrap" onSubmit={handleSubmit}>
        <div className="form_step_wrap">
          <div className="form_step_item">
            <div className="form_head_cont">
              <h1 className="form_head">Door Screen</h1>
              {/* <div className="form_note">
                <p>
                  Door Screen options
                </p>
              </div> */}
            </div>
            <div className="form_wrap">
              <div className="form_row">
                <div className="form-group">
                  <label className="lbl_main">
                    Would you like to add a screen (for an additional cost?)
                  </label>
                  <div className="door_gif_wrap">
                  {doorScreenGif
                  && <img src={doorScreenGif} alt="Door Screen" className="Door Gif" />
                  }
                  </div>
                  <div className="form-check-img-wrap form-check-img-wrap-new">
                    {screenOptions.length > 0 &&
                      screenOptions.map((item, index) => {
                        return (
                          <div className="form-check" key={`door_screen_${index}`}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="door_screen"
                              id={`door_screen${index}`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              key={"input-"+item+index}
                              value={item}
                              defaultChecked= {String(values.door_screen).toLowerCase() === String(item).toLowerCase() }
                            />
                            <label
                              className="form-check-label form-check-img-item"
                              htmlFor={`door_screen${index}`}
                            >
                              {String(item).toUpperCase()}
                            </label>
                          </div>
                        );
                      })}
                    {touched.door_screen &&
                    errors.door_screen ? (
                      <div className="custom-invalid-feedback">
                        {errors.door_screen}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_wrap btn_two_wrap d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-primary btn_sec w-auto"
            onClick={handlePrevious}
            disabled={doorIsLoading}
          >
            <i className="bi bi-chevron-left"></i> Back
          </button>
          <button
            type="button"
            onClick={nextStep}
            className="btn btn-primary btn_main w-auto"
            disabled={doorIsLoading}
          >
            Next <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default DoorScreen;
