import React from "react";

const NotFound = () => {
    
    return (
      <div className="main_wrap">
          <div className="form_step_wrap">
          <div className="form_step_item">
              <div className="form_head_cont">
                <h1 className="form_head">404</h1>
                <h4 className="form_subhead">Page Not Found</h4>
                <div className="form_note">
                  <p>We're sorry, but the page you are looking for doesn't exist.
                  It might have been moved, deleted, or the URL may be incorrect.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="btn_wrap btn_two_wrap d-flex justify-content-center">
          <a href="/">
            <button type="button" className="btn btn-primary quote_btn mx-2">
              <i className="bi bi-chevron-left"></i> Get New Quote
            </button>
          </a>
          </div>
      </div>
    )
};

export default NotFound;