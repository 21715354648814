// header.js
import React from 'react';
import logo from '../logo.png';

const Header = ({ nextStep, handleChange, values }) => {
  return (
    <header className='header_wrap'>
      <div className='container'>
        <img src={logo} alt="Logo" className="header-logo" />
      </div>
    </header>
  );
};

export default Header;
